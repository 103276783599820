export default {
  version: '1.1.03',
  id: 'default',
  title: 'OrderAdmin',
  debug: false,
  app: 'orderadmin',
  status: 'https://statuspage.freshping.io/53709-Orderadmin',
  knowledge: 'https://oawms.com/knowledgebase/',
  support: 'mailto:support@oawms.com',
  domain: '',
  localServer: 'app.orderadmin.ru',
  defaultServer: 'https://pro.oawms.com',
  webService: 'wss://centrifugo.orderadmin.eu/connection/websocket',
  securityLogo: 'assets/logos/new_robot.png',
  logoSrc: 'assets/logos/logo.png',
  logoLeft: 'assets/logos/logo-nosign.png',
  logoTop: 'assets/logos/logo-nosign.png',
  fallbackImage: 'assets/img/fallback-image/package.png',
  notificationsSounds: {
    default: '/assets/audio/Air Plane Ding-SoundBible.com-496729130.mp3', // Elevator Ding-SoundBible.com-685385892.mp3
    warning: '/assets/audio/tasmanian-devil-daniel_simon.mp3',
    error: '/assets/audio/Computer Error-SoundBible.com-1655839472.mp3'
  },
  formats: {
    internationalDate: 'LLL',
    date: 'DD.MM.YYYY',
    fullDate: 'DD.MM.YYYY HH:mm z',
    defaultTimezone: 'Europe/Sofia'
  },
  theme: 'default',
  themes: [
    {
      name: 'Default',
      theme: 'default'
    }
  ],
  themeSettings: {
    default: {
      headerColor: 'bg-white text-grey-8',
      sidebarColor: 'bg-white',
      sidebarTextColor: 'text-gray-8',
    }
  },
  locale: {
    name: 'English',
    locale: 'en',
    flag: 'us'
  },
  locales: [
    {
      name: 'English',
      locale: 'en',
      flag: 'us'
    },
    {
      name: 'Български',
      locale: 'bg',
      flag: 'bg'
    },
    {
      name: 'Русский',
      locale: 'ru',
      flag: 'ru'
    }
  ],
  tables: {
    paginationOptions: {
      enabled: true,
      mode: 'pages',
      perPage: 25
    }
  },
  googleApiKey: 'AIzaSyAjsWnMXpwZKAMMVENXoGNuTLDHsvBACVg',
  storage: {
    parents: [
      {
        name: 'Новосибирск',
        id: 861
      },
      {
        name: 'Новосибирск',
        id: 861
      }
    ]
  },
  labellingSettings: {
    notification: {
      shippingManifest: false,
      labelConfirm: false
    },
  },
  packagingSettings: {
    acceptanceControl: false,
    consumptionControl: false,
    weightControl: true,
    maxWeight: 14000,
    consumption: null,
    checkParcelContent: false,
    trustIncomingMeasurements: false,
    setPredefinedMeasurements: false,
    isEnabledDeliveryRequestTable: false,
    default: {
      weight: 1000,
      width: 100,
      length: 100,
      height: 100,
      sendDate: null
    },
    printing: {
      interceptAutoClose: false,
      autoClosePrintWindow: true,
      modal: false,
      timeout: 4500
    }
  },
  defaultDimensions: {
    weight: 1000,
    dimensions: {
      x: 100,
      y: 100,
      z: 100
    },
    weightUnit: 'g',
    dimensionUnit: 'mm'
  },
  refreshTimeout: 5000,
  barcodeFieldTimeout: 15000,
  servers: [
    {
      name: 'Global',
      domain: '',
      host: 'https://pro.oawms.com'
    },
    {
      name: 'EU',
      domain: '',
      host: 'https://panel.orderadmin.eu'
    },
    {
      name: 'Alpha',
      domain: '',
      host: 'https://alpha.orderadmin.eu'
    },
  ],
  deliveryServices: {
    filter: []
  },
  locality: {
    filter: []
  },
  accountCreateRoles: [],
  isRequiredDeliveryRequest: false,
  wizardSettings: {},
  appSettings: {
    chat: {
      withoutAccess: [8]
    },
    sounds: {
      routesWithSounds: [
        '/packaging',
        '/items-info',
        '/terminal/acceptances',
        '/move-items',
        '/terminal/acceptance',
        '/wholesale-consumptions',
        '/return-parcels',
        '/attach-place',
        '/item-placing',
        '/items-for-measuring',
        '/picking',
        '/sorting',
        '/inventories',
        '/order-packaging',
        '/order-assembling'
      ]
    },
    routeOptions: {
      acceptance: {
        additionalProperties: {
          disabledFor: {
            appOptions: ['cdek', 'smartprepcenter',],
            roles: []
          }
        },
        deliveryRequest: {
          disabledFor: {
            appOptions: ['cdek', 'reworker', 'kz', 'callplex', 'shippedbg', 'cargoplanetbg', 'smartprepcenter'],
            roles: []
          }
        },
        deliveryRequestFields: {
          disabledFor: {
            appOptions: [],
            roles: []
          }
        },
        statusFor: {
          disabledFor: {
            appOptions: ['smartprepcenter'],
            roles: []
          }
        }
      },
      address: {
        additionalProperties: {
          disabledFor: {
            appOptions: ['lifjot'],
            roles: []
          }
        }
      }
    },
    routesAccess: {
      terminal: [7, 3, 29],
      workplace: [7, 3, 29],
      user: [29, 3],
      sender: [3, 8, 29],
      shop: [3, 8, 29],
      warehouse: [3, 8, 29],
      storagePlace: [3, 7, 29],
      shippingIntegration: [3, 29],
      ecommerceIntegration: [3, 29],
      notificationChannels: [3, 29],
      notificationLogs: [3, 29],
      notificationRules: [3, 29],
      notificationTemplates: [3, 29],
      interactiveNotification: [3, 29],
      legalEntities: [3, 8, 29],
      marketplaceSync: [3, 8, 29],
      billing: [36, 3, 29],
      documents: [3, 29],
      accounts: [8, 3, 29],
      management: [7, 3, 29],
      settings: [3, 7, 8, 29],
      apps: [3, 29],
      permissions: [3]
    },
    routesWithoutAccess: {}
  },
  disabledPostcodeVerification: true,
  multiprintService: [45894],
  wizardTabs: {
    warehouse: {
      state: 'active',
      type: 'virtual'
    }
  },
  wizardRequirments: {
    // In that way we restrict only users with that role to have wizard
    roles: [8],
    // If user has more than that it will not pass requirments
    setup: 100
  },
  defaults: {
    addressFormat: 'international',
    order: {
      paymentState: 'not_paid'
    }
  },
  orderTypes: {
    retail: {
      type: 'retail',
      label: 'Simple',
      options: {
        type: 'retail'
      }
    },
    wholesaleDraft: {
      type: 'wholesaleDraft',
      label: 'Wholesale Draft',
      options: {
        type: 'wholesale',
        state: 'new'
      }
    },
    wholesale: {
      type: 'wholesale',
      label: 'Wholesale',
      options: {
        type: 'wholesale'
      }
    },
    return: {
      type: 'return',
      label: 'Return',
      options: {
        type: 'return',
        state: 'new'
      }
    },
    bundle: {
      type: 'bundle',
      label: 'Bundle',
      options: {
        type: 'bundle'
      }
    },
    disposal: {
      type: 'disposal',
      label: 'Disposal',
      options: {
        type: 'disposal'
      }
    }
  },
  senderWarning: true,
  termsLink: '#',
  cookiesLink: '#'
}
