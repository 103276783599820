export class Runtime {
  apps;

  mountedApps;

  constructor() {
    this.mountedApps = [];
    this.apps = new Map();
  }

  getApp(name) {
    return this.apps.get(name);
  }

  setApp(app) {
    if (this.apps.get(app.targetName)) {
      throw new Error(`App with name ${app.targetName} already exist!`);
    }

    this.apps.set(app.targetName, app);
    return app;
  }

  rerenderByRules(targetName, rules) {
    const app = this.mountedApps.find((app) => app.targetName === targetName);

    if (app) {
      const shouldUnmount = !app.rules(rules);

      if (shouldUnmount) {
        app.unmount();
        this.mountedApps = this.mountedApps.filter((app) => app.targetName !== targetName);
      }

      return app;
    }

    const AppConstructor = this.apps.get(targetName);

    if (AppConstructor && AppConstructor.rules(rules)) {
      const newApp = new AppConstructor();
      newApp.mount(rules);
      this.mountedApps.push(newApp);
      return newApp;
    }

    return undefined;
  }
}
