export default {
  id: 'smartprepcenter',
  debug: false,
  title: 'Smart Prep Center',
  domain: 'smartprepcenter',
  localServer: 'prep.smartprepcenter.com',
  defaultServer: 'https://pro.oawms.com',
  securityLogo: 'assets/logos/logo_SmartPrepCenter-black.png',
  logoSrc: 'assets/logos/logo_SmartPrepCenter-black.png',
  logoLeft: 'assets/logos/logo_SmartPrepCenter-black.png',
  logoTop: 'assets/logos/logo_SmartPrepCenter-black.png',
  knowledge: 'https://smartprepcenter.com/support/',
  support: 'https://smartprepcenter.com/support/support-for-fba/',
  theme: 'default',
  formats: {
    internationalDate: 'LLL',
    date: 'MM/DD/YYYY',
    fullDate: 'MM/DD/YYYY hh:mm A z',
    defaultTimezone: 'America/New_York',
  },
  locales: [
    {
      name: 'English',
      locale: 'en',
      flag: 'us',
    },
  ],
  servers: [],
  domains: [],
  wizardTabs: {
    warehouse: {
      state: 'active',
      type: 'virtual',
    },
  },
  labellingSettings: {
    notification: {
      shippingManifest: false,
      labelConfirm: 'Places are ok?',
    },
  },
  defaultDimensions: {
    weight: 1,
    dimensions: {
      x: 10,
      y: 10,
      z: 10,
    },
    weightUnit: 'lb',
    dimensionUnit: 'in',
  },
  orderTypes: {
    fbaAsap: {
      type: 'fbaAsap',
      label: 'FBA ASAP',
      options: {
        type: 'wholesale',
        eav: {
          'storage-reserve-section': 116023,
        },
      },
      settings: {
        disableProfile: true,
        loadSingleSource: true,
      },
      validators: [
        {
          field: 'source',
          required: true,
        },
      ],
    },
    fbaPallet: {
      type: 'fbaPallet',
      label: 'FBA Pallet',
      options: {
        type: 'wholesale',
        eav: {
          'storage-reserve-section': 117251,
        },
      },
      settings: {
        disableProfile: true,
        loadSingleSource: true,
      },
      validators: [],
    },
    fba: {
      type: 'fba',
      label: 'FBA',
      options: {
        type: 'wholesale',
        eav: {
          'storage-reserve-section': '117251,114813,123213',
        },
      },
      settings: {
        disableProfile: true,
        loadSingleSource: true,
      },
      validators: [
        {
          field: 'source',
          required: true,
        },
      ],
    },
    retail: {
      type: 'retail',
      label: 'Simple',
      options: {
        type: 'retail',
      },
    },
    wholesaleDraft: {
      type: 'wholesaleDraft',
      label: 'Wholesale Draft',
      options: {
        type: 'wholesale',
        state: 'new',
      },
    },
    wholesale: {
      type: 'wholesale',
      label: 'Wholesale',
      options: {
        type: 'wholesale',
      },
    },
    return: {
      type: 'return',
      label: 'Return',
      options: {
        type: 'return',
      },
    },
    bundle: {
      type: 'bundle',
      label: 'Bundle',
      options: {
        type: 'bundle',
      },
    },
    disposal: {
      type: 'disposal',
      label: 'Disposal',
      options: {
        type: 'disposal',
      },
    },
  },
  productTypes: {
    simple: {
      type: 'simple',
      label: 'Simple',
      options: {
        icon: 'add_circle',
      },
    },
    grouped: {
      type: 'grouped',
      label: 'Grouped',
      options: {},
    },
    bundle: {
      type: 'bundle',
      label: 'Bundle',
      options: {},
    },
  },
};
