import { runtime } from '..';
import { EventBus } from '../event-bus';

export function createApp(data) {
  const eventBus = data.eventBus || new EventBus();

  const AppConstructor = class extends HTMLElement {
    root;

    targetName;

    rules;

    app;

    static targetName = data.name;

    static rules = data.rules;

    constructor() {
      super();

      this.root = data.root;
      this.targetName = data.name;
      this.rules = data.rules;
    }

    unmount() {
      if (this.app) {
        this.app.unmount();
      }

      this.remove();
    }

    mount(rules) {
      this.root.appendChild(this);

      const container = document.createElement('div');
      this.appendChild(container);

      return data.bootstrap(container, eventBus, data.options && data.options(rules))
        .then((app) => {
          this.app = app;
          return this.app.mount(container);
        });
    }
  };

  customElements.define(data.name, AppConstructor);
  runtime.setApp(AppConstructor);

  eventBus.subscribe('rules', (rules) => {
    runtime.rerenderByRules(data.name, rules);
  });

  return eventBus;
}
