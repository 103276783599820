/* eslint-disable */

export class Subject {
  lastValue
  subscribers

  get value () {
    return this.lastValue
  }

  constructor () {
    this.subscribers = []
  }

  getID () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      const r = Math.random() * 16 | 0;
      const v = c == 'x' ? r : (r&0x3 | 0x8);

      return v.toString(16);
    })
  }

  publish (data) {
    
    this.lastValue = data
    this.subscribers.forEach(({ callback }) => {
      callback(data)
    })
  }

  subscribe (callback) {
    const subscriber = {
      id: this.getID(),
      callback
    }

    this.subscribers.push(subscriber)

    return {
      unsubscribe: () => {
        this.subscribers = this.subscribers.filter(({ id }) => id !== subscriber.id)
      }
    }
  }
}
