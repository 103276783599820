function authHeader() {
  // return authorization header with jwt token
  const user = JSON.parse(localStorage.getItem('user'));

  if (user && user.access_token) {
    return { Authorization: `Bearer ${user.access_token}` };
  }
  return {};
}

/**
 * Parse Text
 *
 * @param {String} text
 *
 * @returns {Any}
 */
function parseText(text) {
  if (text && (text[0] === '[' || text[0] === '{')) {
    return JSON.parse(text);
  }

  return text;
}

/**
 * Handle Response
 *
 * @param {Object} response
 *
 * @returns {Promise<Object>}
 */
export function handleResponse(response) {
  return response.text().then((text) => {
    const data = parseText(text);

    if (response.status !== 200 && response.status !== 201) {
      const result = parseText(text);
      response.message = (result && result.detail) || response.statusText;
      return Promise.reject(response);
    }

    return data;
  });
}

export const UserService = {
  login,
  refreshAuth,
  logout,
  register,
  getById,
  save,
};

/**
 * Login
 *
 * @param {String} username
 * @param {String} password
 * @param {String} server
 *
 * @returns {Promise<Object>}
 */
function login(username, password, server = null) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      grant_type: 'password',
      client_id: window.appOptions.app,
      username,
      password,
      domain: window.appOptions.domain,
    }),
  };

  if (server === null) {
    server = window.appOptions.defaultServer;
  }

  return fetch(`${server}/oauth`, requestOptions)
    .then(handleResponse)
    .then((user) => user);
}

/**
 * Login with refresh token
 *
 * @param {String} refreshToken
 * @param {String} server
 *
 * @returns {Promise<Object>}
 */
function refreshAuth(refreshToken, server = null) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      grant_type: 'refresh_token',
      client_id: window.appOptions.app,
      refresh_token: refreshToken,
      domain: window.appOptions.domain,
    }),
  };

  if (server == null) {
    server = window.appOptions.defaultServer;
  }

  return fetch(`${server}/oauth`, requestOptions)
    .then(handleResponse)
    .then((user) => user);
}

/**
 * Log Out
 */
function logout() {
  // remove user from local storage to log user out
  localStorage.clear();
}

/**
 * Register
 *
 * @param {Object} user
 *
 * @returns {Promise<Object>}
 */
function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };

  return fetch(`${window.appOptions.defaultServer}/users/register`, requestOptions).then(handleResponse);
}

/**
 * Get User by ID
 *
 * @param {Number} id
 *
 * @returns {Promise<Object>}
 */
function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${window.appOptions.defaultServer}/api/users/users/${id}`, requestOptions).then(handleResponse);
}

/**
 * Save User
 *
 * @param {Number} id
 * @param {Object} data
 *
 * @returns {Promise<Object>}
 */
function save(id, data) {
  const requestOptions = {
    method: 'PATCH',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  };

  return fetch(`${window.appOptions.defaultServer}/api/users/users/${id}`, requestOptions).then(handleResponse);
}
