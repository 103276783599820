export default {
  id: 'luckyprep',
  debug: false,
  app: 'orderadmin',
  title: 'Luckyprep',
  domain: 'luckyprep',
  localServer: 'luckyprep.net',
  defaultServer: 'https://pro.oawms.com',
  securityLogo: 'assets/logoLuckyprep.png',
  logoSrc: 'assets/logoLuckyprep.png',
  logoLeft: 'assets/logoLuckyprep.png',
  logoTop: 'assets/logoLuckyprep.png',
  theme: 'default',
  formats: {
    internationalDate: 'LLL',
    date: 'MM/DD/YYYY',
    fullDate: 'MM/DD/YYYY hh:mm A z',
    defaultTimezone: 'America/Chicago',
  },
  servers: [],
  locale: {
    name: 'English',
    locale: 'en',
    flag: 'us',
  },
  automaticCreatedEntities: {
    mysend: {
      sender: {},
      shop: {},
    },
  },
  defaults: {
    addressFormat: 'international',
    order: {
      paymentState: 'paid',
    },
  },
  packagingSettings: {
    acceptanceControl: false,
    consumptionControl: false,
    weightControl: true,
    maxWeight: 14000,
    consumption: null,
    checkParcelContent: false,
    trustIncomingMeasurements: true,
    setPredefinedMeasurements: false,
    isEnabledDeliveryRequestTable: false,
    default: {
      weight: 1,
      width: 10,
      length: 10,
      height: 10,
      sendDate: null,
    },
    printing: {
      interceptAutoClose: false,
      autoClosePrintWindow: true,
      modal: false,
      timeout: 4500,
    },
  },
  defaultDimensions: {
    weight: 1,
    dimensions: {
      x: 10,
      y: 10,
      z: 10,
    },
    weightUnit: 'lb',
    dimensionUnit: 'in',
  },
  orderTypes: {
    fba: {
      type: 'fba',
      label: 'FBA',
      options: {
        type: 'wholesale',
      },
      settings: {
        disableProfile: true,
        loadSingleSource: true,
      },
      validators: [
        {
          field: 'source',
          required: true,
        },
      ],
    },
    retail: {
      type: 'retail',
      label: 'Simple',
      options: {
        type: 'retail',
      },
    },
    wholesaleDraft: {
      type: 'wholesaleDraft',
      label: 'Wholesale Draft',
      options: {
        type: 'wholesale',
        state: 'new',
      },
    },
    wholesale: {
      type: 'wholesale',
      label: 'Wholesale',
      options: {
        type: 'wholesale',
      },
    },
    return: {
      type: 'return',
      label: 'Return',
      options: {
        type: 'return',
      },
    },
    bundle: {
      type: 'bundle',
      label: 'Bundle',
      options: {
        type: 'bundle',
      },
    },
    disposal: {
      type: 'disposal',
      label: 'Disposal',
      options: {
        type: 'disposal',
      },
    },
  },
};
