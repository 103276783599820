const instructions = {
  en: {
    order: {
      import: 'https://oawms.com/knowledgebase/2-4-3-import-orders-with-file/',
    },
    acceptance: {
      import: 'https://oawms.com/knowledgebase/2-3-3-import-a-po-with-exel-file/',
    },
    offer: {
      import: 'https://oawms.com/knowledgebase/2-2-3-import-product-by-excel/',
    },
    deliveryRequest: {
      import: 'https://oawms.com/knowledgebase/22-5-import-shipments-with-file/',
    },
  },
  ru: {},
  bg: {
    order: {
      import: 'https://izprati.bg/knowledgebase/2-4-3-import-orders-with-file/',
    },
    acceptance: {
      import: 'https://izprati.bg/knowledgebase/2-3-3-import-a-po-with-exel-file/',
    },
    offer: {
      import: 'https://izprati.bg/knowledgebase/2-2-3-import-product-by-excel/',
    },
    deliveryRequest: {
      import: 'https://izprati.bg/knowledgebase/22-5-import-shipments-with-file/',
    },
  },
  th: {},
};

const handler = {
  get: (target, key) => {
    if (!Object.keys(instructions).includes(key)) {
      return target[window.appOptions.locale.locale][key]
        ? target[window.appOptions.locale.locale][key]
        : target.en[key];
    }

    return target[key];
  },
};

export default new Proxy(instructions, handler);
