import DefaultOptions from './AppOptions'
import OAWms from './oawms/AppOptions'
import AppDevOptions from './app-dev/AppOptions'
import SmartPrepCenterOptions from './smartprepcenter/AppOptions'
import PrepadminOptions from './prepadmin/AppOptions'
import DemoOptions from './demo/AppOptions'
import Lifjot from './lifjot/AppOptions'
import Salesinusa from './salesinusa/AppOptions'
import Luckyprep from './luckyprep/AppOptions'
import Longroad from './longroad/AppOptions'
import Izprati from './izprati/AppOptions'

export default [
  DefaultOptions,
  AppDevOptions,
  OAWms,
  SmartPrepCenterOptions,
  PrepadminOptions,
  DemoOptions,
  Lifjot,
  Salesinusa,
  Luckyprep,
  Longroad,
  Izprati,
]
