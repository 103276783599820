import AppOptions from './config/AppOptions';
import Workspaces from './config/Workspaces';

/**
 * Init Workspace
 * Find config based on location
 *
 * @param {string|undefined|null} domain
 *
 * @returns {object}
 */
export function initWorkspace(domain) {
  let currentAppOptions = JSON.parse(localStorage.getItem('appOptions'));
  let isWorkspacesEnable = JSON.parse(localStorage.getItem('isWorkspacesEnable'));

  // Handle if we have new appOptions
  if (currentAppOptions && currentAppOptions.version !== AppOptions.version) {
    localStorage.clear();
    currentAppOptions = null;
  }

  // Check is valid options.
  // Current app options are merged and they must have >= properties than base app options
  if (!currentAppOptions || Object.keys(AppOptions).length - 3 > Object.keys(currentAppOptions).length) {
    const workspace = Workspaces.find((config) => location.hostname === config.localServer);

    currentAppOptions = {
      ...AppOptions,
      ...(workspace || {}),
    };

    currentAppOptions.domain = domain || currentAppOptions.domain;
    isWorkspacesEnable = !workspace || workspace.id === 'app-dev' || location.hostname.includes('localhost');

    localStorage.setItem('isWorkspacesEnable', JSON.stringify(isWorkspacesEnable));
    localStorage.setItem('appOptions', JSON.stringify(currentAppOptions));
  }

  const hosts = ['localhost', 'app.orderadmin', 'app-dev.orderadmin', 'app.oawms'];

  window.appOptions = currentAppOptions;
  window.isWorkspacesEnable = isWorkspacesEnable;
  window.isUnlockedDomain = !!hosts.find((x) => location.hostname.includes(x)) || currentAppOptions.id === 'default' || currentAppOptions.id === 'app-dev';

  document.getElementById('favicon').href = currentAppOptions.logoSrc;
  document.getElementById('app-title').textContent = currentAppOptions.title || 'OrderAdmin';

  return currentAppOptions;
}
